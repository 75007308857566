import React, { useEffect, useState, useCallback } from 'react';
import { inject, observer } from 'mobx-react';

import { InvisibleSmartCaptcha } from '@yandex/smart-captcha';
import WidgetForm from '../components/WidgetForm';
import { CheckboxField } from '../components/CheckboxField';
import { LoginInputField } from './LoginInputField';
import { getPhoneInServerFormat } from '../misc/utils';
import { SubmitButton } from '../components/SubmitButton';
import { actions } from '../store/store';
import { phoneValidationSchema } from '../validation/loginValidation';

const SignInPage = inject('store')(observer(() => {
  const [consent, setConsent] = useState(false);
  const [phone, setPhone] = useState(null);

  const [token, setToken] = useState('');
  const [visible, setVisible] = useState(false);

  const handleChallengeHidden = useCallback(() => setVisible(false), []);

  const handleSubmit = async () => {
    try {
      if (token && phone) {
        // const phoneNumber = getPhoneInServerFormat(values.phoneNumber);
        await actions.checkPhoneNumber(phone, token);
        setToken(null);
        setVisible(false);
      }
    } catch (e) {
      return e;
    }
    return null;
  };

  useEffect(() => {
    if (token) { handleSubmit(); }
  // eslint-disable-next-line
  }, [token]);

  return (
    <WidgetForm
      title='Войти в личный кабинет'
      onSubmit={ (values) => {
        setPhone(getPhoneInServerFormat(values.phoneNumber));
        if (consent) { setVisible(true); }
      } }
      validationSchema={ phoneValidationSchema }
    >
      <>
        <LoginInputField name='phoneNumber' autoFocus />
        <CheckboxField
          name='consentToTheProcessing'
          label='Согласен на обработку персональных данных'
          checked={ consent }
          onClick={ () => setConsent(!consent) }
          disabled={ visible }
        />
        <SubmitButton disabled={ !consent || visible }>Продолжить</SubmitButton>
        { visible
        && (
          <InvisibleSmartCaptcha
            sitekey={ process.env.YANDEX_CAPTCHA_KEY }
            onSuccess={ setToken }
            onChallengeHidden={ handleChallengeHidden }
            visible={ visible }
          />
        )}
      </>
    </WidgetForm>
  );
}));

export { SignInPage };
