import React, { useEffect } from 'react';
import 'fontsource-roboto';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Provider } from 'mobx-react';

import { ThemeProvider } from '@material-ui/core/styles';
import { actions, store } from './store/store';
import { AuthWidget } from './containers/AuthWidget';
import { listenMessages } from './messaging/messaging';
import { sendMessage } from './messaging/windowMessenger';
import { vezemKolesaTheme } from './styles/vezemKolesaTheme';
import './styles/main.scss';

function App() {
  const getThemeByProjectId = () => {
    return vezemKolesaTheme;
  };

  useEffect(() => {
    listenMessages();
    sendMessage({ isLoaded: true });
    actions.getGenders();
  });

  return (
    <Provider store={ store }>
      <ThemeProvider theme={ getThemeByProjectId() }>
        <CssBaseline />
        <Container maxWidth='sm' fixed>
          <AuthWidget />
        </Container>
      </ThemeProvider>
    </Provider>
  );
}

export { App };
