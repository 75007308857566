// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/fonts/OpenSansCondensed/OpenSansCondensed-Light.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "OpenSansCondensed";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
}
body, html {
  background-color: #fff !important;
}`, "",{"version":3,"sources":["webpack://./src/styles/main.scss"],"names":[],"mappings":"AAAA;EACE,gCAAA;EACA,+DAAA;AACF;AAEA;EACE,iCAAA;AAAF","sourcesContent":["@font-face {\n  font-family: \"OpenSansCondensed\";\n  src: url(\"../assets/fonts/OpenSansCondensed/OpenSansCondensed-Light.ttf\") format(\"truetype\");\n}\nbody, html {\n  background-color: #fff !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
