import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { inject, observer } from 'mobx-react';

import { Routes, BrowserRouter } from 'react-router-dom';
import { Route } from 'react-router';
import { actions } from '../store/store';
import { SignInPage } from './SignInPage';
import { PhoneVerifyPage } from './PhoneVerifyPage';
import { ClientUpdatePage } from './ClientUpdatePage';
import * as paths from '../misc/paths';

const AuthWidget = inject('store')(observer(() => {
  useEffect(() => {
    actions.gotoHomeIfNoLoginPresent();
  });

  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      style={ { minHeight: '100vh' } }
    >
      <Grid item xs={ 12 } md={ 10 }>
        <Box p={ 3 } style={ { minHeight: '100vh' } }>
          <BrowserRouter>
            <Routes>
              <Route path='/' element={ <SignInPage /> } />
              <Route path={ paths.PHONE_VERIFY } element={ <PhoneVerifyPage /> } />
              <Route path={ paths.CLIENT_UPDATE } element={ <ClientUpdatePage /> } />
            </Routes>
          </BrowserRouter>
        </Box>
      </Grid>
    </Grid>
  );
}));

export { AuthWidget };
